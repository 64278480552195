noscript.
  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WP98BSK"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>

.footer
  .primary-container
    .container#footer-hr
      .row(style="padding-top: 50px;")
        .col-md-3
          h4 Explore
          a(href='about.html') About us
          a(href='blog.html') Blog
          br
        .col-md-3
          h4 Resources
          a(href='faq.html') Frequently Asked
          a(href='mailto:support@choosepicnic.com') support@choosepicnic.com
          
          
          br
        .col-md-6
          //- h4 Supported by
          //- br
          //- img.small(src='https://i0.wp.com/www.vccafe.com/wp-content/uploads/2017/09/Y_Combinator_logo_text_wordmark.png?ssl=1')
                   

    .container
      img.tiny.closer(src="../images/picnic-logo.png")