.navbar(style='border-bottom: 2px solid #f1f1f1;').top
    .nav-items
        .nav-burger
            span.open(id="open" onclick="openNav()") &#9776
        a.nav-logo(href='/')
            img.medium(src='images/picnic-logo.png' style='opacity: .8;')
        .nav-menu
            //- a.nav-link(href="index.html") Home
            a.nav-link(href='about.html') About us
            a.nav-link(href='blog.html') Blog
            a.nav-link.demo-link(href='https://picnic385059.typeform.com/to/jv1HLB') Join waitlist
            
            form(action="https://picnic385059.typeform.com/to/jv1HLB" method="GET" target="_blank")
                input#email_submit.button.button-nav-green(type="submit" value="Join waitlist")